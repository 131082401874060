import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Image from "../components/Image";
import "./menu.css";

const BakedGood = ( { image, name, description, price } ) => (
  <li
    className="item"
    itemScope
    itemType="http://schema.org/Product"
  >
    <div style={{
      height: "12px",
      width: "100%",
      backgroundColor: "#fecc02",
      transform: "skew(-45deg, 0deg)",
      margin: "6px 0",
    }} />
    <section className="goods-description">
      <div className="goods-image" style={{
        flexShrink: 0,
      }}>
        <Image />
      </div>
      <div >
        <div className="header">
          <h3 itemProp="name" style={{ color: "#016AA7", margin: "12px 0" }}>{name}</h3>
        </div>
        <div className="description">
          <p itemProp="description">{description}</p>
          <p itemProp="price" content={`$${price}`}>${price}</p>
        </div>
      </div>
    </section>
  </li>
)

const IndexPage = () => (
  <Layout>
    <SEO title="Svenska's Culinary - Menu" />
    <h2 className="">Our Menu</h2>
    <p>Our menu of goods combines traditional Swedish flavors with the best of American home cooking.</p>
    <ul className="content" style={{ listStyle: "none", margin: 0 }}>
      <BakedGood
        name="Swedish Apple Cake"
        description="Traditional apple cake made from fresh apples."
        price="10.00"
      />
      <BakedGood
        name="Ginger Cookies"
        description="Snappy ginger cookies with a crackly sugar top."
        price="10.00"
      />
      <BakedGood
        name="Orange Date Bread"
        description="Oranges and dates married together in perfect harmony."
        price="10.00"
      />
      <BakedGood
        name="English Lemon Tea Bread"
        description="The perfect accompaniment to your morning cuppa."
        price="10.00"
      />
      <BakedGood
        name="Blueberry Crumb Coffee Cake"
        description="Gluten free! Crumbly baked brown sugar top and fresh blueberries."
        price="10.00"
      />
    </ul>
  </Layout>
)

export default IndexPage
